@font-face {
  font-family: DDIN;
  src: url("../fonts/D-DIN.otf") format("opentype");
}

body, html {
  height: 100%;
  font-family: 'DDIN';
  margin:0;
}

p{
font-size: 18px;
margin: 0;
}

h1{
font-size: 30px;
color: white;
}

h2{
flex:1;
font-size: 22px;
color: white;
margin: 0;
}

li{
font-size: 20px;
text-decoration: none;
}

a{
text-decoration: none;
color: black;
}

.blackFont{
color: black;
}

header{
  display: flex;
  align-items: center;
  padding: 0.5% 2% 0.5% 2%;
  justify-content: space-between;
  right: 0;
  left: 0;
  top: 0;
  background-color: white;
  min-height: 50px;
  flex: 2;
}

.HeaderContainer{
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 120;
  height: 120px;
  width: 100%;
  top: 0px;
}

.logo {
  height: 100%;
  width: auto;
}

.PromotionBanner{
  flex: 1;
  background-color: #0171bd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PromotionText{
  text-align: center;
  color: white;
}

ul{
  display: flex;
  list-style-type: none;
  align-self: center;
}

a{
  text-decoration: none;
}

.navLink{
  text-decoration: none;
  margin: 10px;
}

.navMenuIcon{
  display: none;
}

.productSubLinks{
  display: flex;
  flex-direction: column;
  border: white 8px solid;
}

.productSubLink{
  background-color: white;
  text-decoration: none;
  color: black;
}

.navLink:hover{
  color: #4190cc;
  margin: 10px;
}

.HeaderReadMoreButton:hover{
  color: #4190cc;
  border: 3px solid #4190cc;
}

.moreInformationHeader:hover{
  color: #4190cc;
}

.faqHeader{
  color:black;
}

.faqHeader:hover{
  color: #4190cc;
}

.grid-item:hover{
  border: 1px solid #4190cc;
}

.readMoreButton:hover{
  background-color: #4190cc;
}

.bannerReadMoreButton{
  color: white;
}

.banner{
  background: linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url(../images/BannerImage1.png);
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: 100% 100%;
  margin-top: 120px;
  align-items: center;
  justify-content: center;
}

.BannerTextArea {
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 5%;
  box-sizing: border-box;
  margin-top: 5%
}

.BannerNavigationBox{
  display: flex;
  margin-bottom: 5%;
  width: 100%;
  text-align-last: center;
}

.BannerNavigationBox {
  display: flex;
  width: 100%;
  text-align-last: center;
  background-size: auto 100%;
}

.leftButtonBox{
  flex:1;
  display: flex;
  padding-left: 5%;
}

.progressImagesBox{
  display: flex;
  align-self: center;
  flex: 1;
  justify-content: center;
}

.rightButtonBox{
  display: flex;
  justify-content: flex-end;
  flex:1;
  padding-right: 5%;
}

.HeaderReadMoreButton{
  color: white;
  border: 3px white solid;
  padding: 10px;
  margin-bottom: 5%;
  padding: 1%;
  box-sizing: border-box;
  background-size: 100%;
}

.checkout-button{
  color: white;
  background-color: #434343;
  padding: 3%;
  flex: 1;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-button-outofstock{
  color: white;
  background-color: #434343;
  padding: 3%;
  flex: 1;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.benefitsSection {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-right: 4%;
  margin-top: 3%;
}

.benefit1, .benefit2, .benefit3, .benefit4{
      display: flex; 
      flex-basis: calc(50% - 40px);  
      justify-content: center;
      flex-direction: row;
      margin: 10px;
}

.benefitImage1, .benefitImage2, .benefitImage3, .benefitImage4 {
  flex: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.benImage1, .benImage2, .benImage3, .benImage4{
  width: 60%;
}

.benefitText1, .benefitText2, .benefitText3, .benefitText4 {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 7.5% 0 7.5% 0;
}
.benefitHeadText1, .benefitHeadText2, .benefitHeadText3, .benefitHeadText4 {
  max-height: inherit;
  flex: 1;
  color: #4190cc;
}

.electrolysisVideoContainer{
display: flex;
flex-direction: column;
align-items: center;
margin-top: 4%;
height: 40vw;
}

.benefitSubtext1, .benefitSubtext2, .benefitSubtext3, .benefitSubtext4 {
  max-height: inherit;
  flex: 1;
  color: black;
}

.readMoreBox{
  display: flex;
  justify-content: flex-end;
  margin-top: 5%
}

.readMoreButton {
  font-family: 'DDIN';
  padding: 13px;
  color: white;
  background-color: #434343;
  padding: 3%;
}

.explanationSection {
  background-image: url(../images/explanationImage.png);
  background-size: 100% 100%;
  display: flex;
  align-items: center;
}

.manuals{
  width: 50%;
  display: flex;
  background: #4190cc;
  color: white;
  height: 35px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  max-width: 200px;
}

.expBox1{
  margin-right: 2.5%;
  margin-left: 5%;
  margin-bottom: 5%;
  margin-top: 5%;
}

.expBox2{
  margin-right: 5%;
  margin-bottom: 5%;
  margin-left: 2.5%;
  margin-top: 5%;
}

.expHeader{
  margin-bottom: 5%;
  text-align: center;
}

.productSection{
  display: flex;
  margin: 5% 4% 0 0;
}

.productImages{
  /* background-color: red; */
  flex: 1;
  display: flex;
  flex-direction: column;
}

.moreInformationWrapper{
  flex-direction: column;
}

.moreInformation-button{
  color: white;
  background-color: grey;
  padding: 3%;
  flex: 1;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainProduct{
  display: flex;
  justify-content: center;

}

.subProducts{
  /* background-color: orange; */
  display: flex;
  justify-content: center;
}

.subProduct1{
  /* background-color: red; */
  flex:1;
  display: flex;
  justify-content: flex-end;
}

.subProduct2{
  /* background-color: teal; */
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.productInformationContainer{
  display: flex;
  margin-top: 6px;
}

.productInformationBoxWrapper{
  margin-top: 10px;
}

.grid-container {
/* max-width: 1335px; */
margin: 0 auto;
margin-top: 120px
}

.grid-row {
display: flex;
flex-flow: row wrap;
justify-content: center;
}

.grid-item {
  flex-basis: 20%;
  -ms-flex: auto;
  width: 259px;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  margin: calc(1px + 1%);
  border: 3px solid #e6e5e5;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
}

.productImage{
  height: 100%;
  width: auto;
  max-width: 99%;
}

.productImageWrapper{
  display: flex;
  justify-content: center;
  height: 60%
}

.productTitle{
  text-align: center;
  color: black;
}

.productPrice{
  text-align: center;
  font-weight: bold;
}

.productILREImage{
  width: 50%;
}

.stockContainer{
  margin-top: 18vw;
  margin-left: 5%;
  margin-right: 5%;
}

.stockItemContainer{
  display: flex;
}

.stockHeaders{
  display: flex;
}

.stockHeader{
  width: 20%;
  font-weight: bold;
}

.stockRecord{
  width: 20%;
  border: 1px solid black;
  display: flex;
}

.stockForm{
  margin-left: 2%;
}

.registration, .login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15vw;
}

@media(max-width: 1333px) {
  .grid-item {
    flex-basis: 25%;
  }
}
@media(max-width: 1073px) {
   .grid-item {
    flex-basis: 33.33%;
  }
}

.productInformation{
  flex: 1;
  text-align: left;
}

.productHeader{
  color: #4190cc;
}

.productSubHeader{
  font-weight: bold;
  color: black;
}

.mainProductImage {
  width: 60%;
  height: auto;
}

.subProductImage1, .subProductImage2 {
  width: 25%;
  height: auto;
  border: 1px solid lightgray;
  margin: 0.5%;
}

.subProductImage1:hover{
border: 1px solid #4190cc;
}

.subProductImage2:hover{
border: 1px solid #4190cc;
}


.footer{
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  align-items: center;
}

.footerLogoContainer{
  display: flex;
  justify-content: center;
  width: 20%;
}

.footerLogo{
  width: 100%;
}

.aboutSummary{
  display: flex;
  flex-direction: row;
  text-align: center;
}

.stepBox{
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 5%
}

.stepHeader{
  font-weight: bold;
  color: black;
}

.stepImage{
  width: 50%;
  height: auto;
}

.aboutExplanationSection{
  background: url(../images/explanationImage.png);
  background-size: 100% 100%;
  display: flex;
  align-items: center; 
  text-align: center;
}

.aboutExplanation{
  color: white;
  margin: 7% 5% 5% 5%;
}

.aboutBenefitsSection{
  display: flex;
  flex-direction: column;
  margin-top: 4%;
}

.aboutBenefitBox1, .aboutBenefitBox2{
  display: flex;
  margin: 2.5% 5% 2.5% 5%;
}

.aboutBenefitBoxImage1, .aboutBenefitBoxImage1{
  order: 2;
  text-align: right;
}

.aboutBenefitBoxImage2, .aboutBenefitBoxImage2{
  text-align: left;
}

.aboutBenefitTextHeader{
  color: #4190cc;
}

.aboutBenefitBoxImage1, .aboutBenefitBoxImage2{
  flex: 1;
}

.aboutBenefitImage{
  width: 30%;
}

.aboutBenefitText{
  flex: 1;
}

.scienceHeader{
font-weight: bold;
align-self: center; 
}

.benefitsHeader{
font-weight: bold;
align-self: center;
color: black
}

.productMain{
  display: flex;
  margin-top: 120px;
}

.productImageBox{
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 5% 0 0 7%; 
}

.statusArrowImageBox{
  align-self: center;
}

.productSelectedImage {
  width: 70%;
  height: auto;
  align-self: center;
  border: 1px solid lightgrey;
  margin-top: 4px;
  margin-bottom: 4px;
}

.productSubImageBox{
  flex: 1;
  display: flex;
  flex-direction: row;
}

.productSubImageOne, .productSubImageTwo, .productSubImageThree{
  flex: 1;
  width: 33%;
  height: auto;
}

.productSummaryBox {
  margin: 5% 5% 0 0;
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productSummaryHeader{
  color: #4190cc;
  text-align: center;
}

.productSummaryMainText{
  text-align: center;
  margin-bottom: 2%;
}

.productBuyNowBox{
  display: flex;
  justify-content: center;
  margin: 2% 0 2% 0;
  display: flex;
  text-align: center;
  flex: auto;
}

.productMainFeaturesBox{
  display: flex;
  margin-top: 50px;
}

.productFeature {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.FeatureImageOne, .FeatureImageTwo, .FeatureImageThree{
  width: 50%;
  height: auto;
}

.FeatureText{
  margin-top: 2%;
}

.extraInformationBox{
  display: flex;
  margin-top: 4%;
}

.extraInformationBoxLeft{
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 2.5% 0 5%
}

.extraInformationBoxRight{
  display: flex;
  justify-content: flex-end;
  margin: 0 5% 0 2.5%;
  flex: 1;
}

.extraInformationHeader{
  color: #4190cc;
  flex:7;
}

.extraInformationArrow{
  flex:1;
  width: 50%;
  height: auto;
}

.extraInformationImage{
  width: 50%;
  height: auto;
}

.extraInformationHeaderBox{
  display: flex;
  margin-bottom: 2%;
  border-bottom: solid 1px #434343
}

.faqBox{
  display: flex;
  flex-direction: column;
  margin: 5% 5% 0 5%;
  margin-top: 160px;
}

.faqHeaderBox{
  display: flex;
  border: solid 1px #434343;
  padding: 0.5%;
}

.faqAnswerBox{
  color: white;
  border: solid 1px #434343;
  padding: 2%;
  background-color: #4190cc;
  white-space: pre-wrap;
}

.faqHeaderArrowImageBox{
  flex: 1;
  text-align: right;
  align-self: center;
}

.contactFormBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7.5%;
}

.productSubLink:hover{
  color: #4190cc;
}

.mobileNavTab{
  display: none;
}

.extraInformationArrow{
  align-self: flex-start;
}


.successContainer{
  display: flex;
  flex-direction: column;
  margin-top: 15%;
  align-items: center;
}

.successParagraph{
  text-align: center;
}

.productBanner{
  height: 20vw;
}

.contactInfoContainer{
  margin-top: 18vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactHeader{
  margin-bottom: 3%;
}

.contactSubtext{
  text-align: center;
}

@media screen and (max-width: 1250px) {
.aboutExplanationSection , .explanationSection{
background-color: #4190cc;
background-image: none;
}
}

@media screen and (max-width: 768px) {

h1{
    font-size: 20px;
}

h2{
    font-size: 18px;
    font-weight: normal;
}

p{
    font-size: 16px;
}

li{
  padding-bottom: 5px;
}

header{
  position: static;
  min-height: 40px;
}  

em{
  font-size: 4vw;
}

.navlink{
  font-size: 25px;
}

.HeaderContainer{
    height: 18vw;
}

.navList{
  display: none;
}

.logo{
  height: 90%;
  width: auto;
  margin-right: 2%;
}

.navMenuIcon{
  display: block;
  height: 50%;
}

.mobileNavTab{
  height: 100%;
  width: 50%;
  background-color: white;
  display: flex;
  position: fixed;
  z-index: 2;
  flex-direction: column;
}

.mobileNavList{
  display: flex;
  flex-direction: column;
  margin: none;
  padding: 0;
}

.mobileNavCloseContainer{
  display: flex;
  justify-content: flex-end;
  margin-top: 2%;
  margin-right: 2%;
}

.mobileNavClose{
  width: 10%;
  max-width: 17px;
}

.HeaderReadMoreButton{
    display: none;
}

.HeaderReadMoreButton:hover{
  color: #4190cc;
  border: 1px solid #4190cc;
}

.BannerNavigationBox{
    display: none;
}

.benefitsSection{
    flex-direction: column;
    margin: 0
}

.benefit1, .benefit3{
    flex-direction: row-reverse;
}

.benefit1, .benefit2, .benefit3, .benefit4{
  margin: 2% 2% 2% 2%;
}

.explanationSection{
    flex-direction: column;
}

.productSection{
    display:none;
}

.aboutSummary{
    flex-direction: column;
}

.stepImage{
    width: 25%;
}

.aboutBenefitBox1, .aboutBenefitBox2{
    flex-direction: column;
}

.aboutBenefitBox1{
    flex-direction: column-reverse;
}

.aboutBenefitTextExplanation{
    text-align: center;
}

.aboutBenefitTextHeader{
    text-align: center;
}

.aboutBenefitBoxImage1, .aboutBenefitBoxImage2{
    text-align: center;
}

.inputEmail, .inputMessage, .inputSubject{
    width: 70%;
}

.explanationSection{
  padding: 7% 0 7% 0;
}

.aboutExplanationSection{
  margin-top: 4%;
}

.productMain{
    flex-direction: column-reverse;
}

.expBox1{
    margin: 10% 0 0 0;
}

.expBox2{
    margin: 0 0 10% 0;
}

.explanationSection{
    padding: 7% 5% 7% 5%;
}

.expSubtext{
    margin: 0;
}

.banner{
  background-size: auto 100%;
}

.extraInformationBox{
    flex-direction: column;
    text-align: center;
}

.extraInformationSubtext{
    margin-bottom: 5%;
}

.extraInformationBoxRight{
    justify-content: center;
}

.extraInformationImage{
  width: 50%;
  height: auto;
}

.grid-item {
  flex-basis: 75%;
  height: 90vw;
}

.banner{
  margin-top: 18vw;
}

.benefitImage1, .benefitImage2, .benefitImage3, .benefitImage4 {
  flex: 1;
}

.banner{
  background-size: 100% 100%;
}

.stepBox{
  margin: 5% 5% 0 5%
}

.aboutBenefitImage{
  width: 40%;
}

.productSelectedImage{
  width:80%;
}

.productImageBox{
  margin:0;
  align-items: center;
}

.productSummaryMainText{
  text-align: center;
}

.contactInfoContainer{
  margin-top: 27vw;
}

.electrolysisVideoContainer{
height: 60vw;
margin-top: 60px;
}

.PromotionBanner{
padding:4px;
}

.PromotionText{
font-size: 12px;
}

.aboutBenefitsSection{
margin-top: 60px;
}
}